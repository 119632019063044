
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import DocumentBase from '@/components/pdf/DocumentBase.vue'
import { DocumentBaseForm } from '@/presentation/affiliate/pdf/DocumentBaseForm'
import dayjs from 'dayjs'
import { Getter } from 'vuex-class'
import { CompletedCorpAll } from '@/gateway/affiliate/model/CorpAll'
import { IdentificationType } from '@/presentation/affiliate/CeoInformationField'
import {
  BusinessCategory, BusinessDivision,
  FundSource, StockInfo,
  TransactionPurpose
} from '@/presentation/affiliate/CompanyInfoField'
import { ShareHolderStatus } from '@/presentation/affiliate/ShareHolderInformationField'
import { koEnum } from '@/lokalise/enumLocale/ko'

@Component({
  components: { DocumentBase }
})
export default class TradeConfirmation extends Vue {
  @Prop(String) containerClass!: string
  @Prop(String) title!: string
  @Prop(Number) currentPage!: number
  @Prop(Number) totalPage!: number

  @Getter getCorpAllState: any

  corpAll!: CompletedCorpAll

  get getToday (): string {
    return dayjs(new Date()).format('YYYY년 MM월 DD일')
  }

  forms: Array<Array<DocumentBaseForm>> = []

  private getFirstPageData (): Array<DocumentBaseForm> {
    const bizLicenseNumber = `${this.corpAll.biz_license_number.substr(0, 3)}-${this.corpAll.biz_license_number.substr(3, 2)}-${this.corpAll.biz_license_number.substr(5)}`
    const phoneNumber = Number(this.corpAll.phone_number)
    const fullPhoneNumber = `${this.corpAll.phone_code} ${phoneNumber}`
    const companyInfo: Array<Array<string>> = [
      ['상호(법인)명', this.corpAll.name],
      ['사업자등록번호', bizLicenseNumber, '사업체 설립일', dayjs(this.corpAll.founded_at).format('YYYY-MM-DD')],
      ['회사 전화번호', fullPhoneNumber, '홈페이지 URL', this.corpAll.homepage.toString()],
      ['회사 주소', `${this.corpAll.line1} ${this.corpAll.line2}`]
    ]
    const isCorporate = this.corpAll.biz_category === Number(BusinessCategory.CORPORATE_BUSINESS)
    if (isCorporate) {
      const corporateAdditional: Array<string> = ['법인구분정보', koEnum[BusinessDivision[this.corpAll.biz_division]], '상장정보', koEnum[StockInfo[this.corpAll.stock_info]]]
      companyInfo.splice(2, 0, corporateAdditional)
    }
    const ceoInfoList: Array<DocumentBaseForm> = this.corpAll.ceo_information_list.map((ceoInfo, index) => {
      const isRegistrationCard = Number(ceoInfo.identification_type) === Number(IdentificationType.REGISTRATION_CARD)
      const identificationLabel: string = isRegistrationCard ? '주민등록번호' : '여권번호'
      const identificationNumber: string = isRegistrationCard ? `${ceoInfo.identification_number?.slice(0, 6)}-${ceoInfo.identification_number?.slice(6)}` : `${ceoInfo.identification_number}`
      const ceoCount: string = this.corpAll.ceo_information_list.length > 1 ? (index + 1).toString() : ''
      const nationality: string = ceoInfo.nationality?.toString() || ''
      const gender: string = ceoInfo.gender === 'female' ? '여자' : '남자'
      return {
        title: `대표자${ceoCount} 신원정보`,
        type: 'table',
        data: [
          ['성명(한글)', ceoInfo.name, '성명(영문)', ceoInfo.english_name],
          ['국적', koEnum[nationality], '생년월일', dayjs(ceoInfo.birthday).format('YYYY-MM-DD')],
          ['성별', gender, identificationLabel, identificationNumber]
        ]
      }
    })
    return [
      {
        type: 'text',
        data: '『특정 금융거래정보의 보고 및 이용 등에 관한 법률』 제5조의 2 및 동법 시행령에 따라 고객이 회사와 거래 시에는 고객확인 및 검증을 위하여 아래의 내용을 기재하셔야 하며, 요청 자료를 제출해 주셔야 합니다. 제공하신 정보는 동 법률에서 정한 용도 외의 목적으로 사용되지 않습니다. 회사는 기재된 정보확인 및 검증을 위해 추가적인 문서 및 자료제출을 요청할 수 있으며, 이를 거부하거나 검증이 불가능한 경우 거래가 제한되거나 거절될 수 있습니다'
      },
      { type: 'divider' },
      {
        title: '사업체 정보',
        type: 'table',
        data: companyInfo
      },
      ...ceoInfoList
    ]
  }

  private getSecondPageData (): Array<DocumentBaseForm> {
    const purposeInfo: Array<Array<string>> = [
      ['거래목적', koEnum[TransactionPurpose[this.corpAll.transaction_purpose]]],
      ['자금의 원천 및 출처', koEnum[FundSource[this.corpAll.fund_source]]]
    ]
    const isEntrepreneur = Number(this.corpAll.biz_category) === Number(BusinessCategory.ENTREPRENEUR)
    const ownerInfoTitleSuffix = isEntrepreneur ? '개인사업자' : '법인사업자'
    const shareHolderStatusNumber = Number(this.corpAll.share_holder_status)
    let ownerBaseForm: Array<DocumentBaseForm>
    let ownerInfo: Array<Array<string>> = []
    let detailOwnerInfo: Array<Array<string>> | undefined
    let subText: string | undefined
    let shareHolderTitle: string = '최대 지분 소유자의 정보'
    let shareHolderInfo: Array<Array<string>> | undefined
    const shareHolder = this.corpAll.share_holder_information
    const nationality: string = shareHolder.nationality?.toString() || ''
    const staticShareHolderInfo: Array<Array<string>> = [
      ['성명(한글)', shareHolder.name, '성명(영문)', shareHolder.english_name],
      ['국적', `${koEnum[nationality]}`, '생년월일', dayjs(shareHolder.birthday).format('YYYY-MM-DD')]
    ]
    switch (shareHolderStatusNumber) {
      case Number(ShareHolderStatus.OWNER):
        ownerInfo = [['실제 소유자 여부', '예']]
        break
      case Number(ShareHolderStatus.SKIPPABLE):
        ownerInfo = [['귀 법인/단체는 실제소유자 확인 생략 가능 법인입니까?', '예']]
        subText = '* 생략 가능 법인 : ‘국가•지자체•공공단체•금융회사’(특정 금융거래정보의 보고 및 이용 등에 관한 법률 제4조의2 제1항 단서의 법인 및 단체에 한함) 및 ‘사업보고서 제출대상법인’(자본시장과 금융투자업에 관한 법률 제159조의 사업보고서 제출대상법인에 한함)인 경우'
        break
      case Number(ShareHolderStatus.QUARTER_UPPER):
        ownerInfo = [['25% 이상의 지분을 소유한 사람이 있는 경우', '예']]
        shareHolderInfo = [
          ...staticShareHolderInfo,
          ['지분율', `${shareHolder.share} %`]
        ]
        break
      case Number(ShareHolderStatus.LARGEST):
        ownerInfo = [
          ['25% 이상의 지분을 소유한 사람이 있는 경우', '아니오'],
          ['위를 확인할 수 없습니다.', '예']
        ]
        detailOwnerInfo = [
          ['1. 최대 지분을 소유한 사람이 있는 경우', '예'],
          ['2. 대표자 또는 임원∙업무집행사원의 과반수를 선임한 주주(자연인)이 있는 경우', '아니오'],
          ['3. 위 두 경우 외에 법인∙단체를 사실상 지배하는 사람이 있는 경우', '아니오'],
          ['4. 모두 확인할 수 없습니다', '아니오']
        ]
        shareHolderTitle = '최대 지분을 소유한 사람의 정보'
        shareHolderInfo = [
          ...staticShareHolderInfo,
          ['지분율', `${shareHolder.share} %`]
        ]
        break
      case Number(ShareHolderStatus.EXECUTIVES):
        ownerInfo = [
          ['25% 이상의 지분을 소유한 사람이 있는 경우', '아니오'],
          ['위를 확인할 수 없습니다.', '예']
        ]
        detailOwnerInfo = [
          ['1. 최대 지분을 소유한 사람이 있는 경우', '아니오'],
          ['2. 대표자 또는 임원∙업무집행사원의 과반수를 선임한 주주(자연인)이 있는 경우', '예'],
          ['3. 위 두 경우 외에 법인∙단체를 사실상 지배하는 사람이 있는 경우', '아니오'],
          ['4. 모두 확인할 수 없습니다', '아니오']
        ]
        shareHolderTitle = '대표자 또는 임원∙업무집행사원의 과반수를 선임한 주주(자연인)의 정보'
        shareHolderInfo = staticShareHolderInfo
        break
      case Number(ShareHolderStatus.DOMINATION):
        ownerInfo = [
          ['25% 이상의 지분을 소유한 사람이 있는 경우', '아니오'],
          ['위를 확인할 수 없습니다.', '예']
        ]
        detailOwnerInfo = [
          ['1. 최대 지분을 소유한 사람이 있는 경우', '아니오'],
          ['2. 대표자 또는 임원∙업무집행사원의 과반수를 선임한 주주(자연인)이 있는 경우', '아니오'],
          ['3. 위 두 경우 외에 법인∙단체를 사실상 지배하는 사람이 있는 경우', '예'],
          ['4. 모두 확인할 수 없습니다', '아니오']
        ]
        shareHolderTitle = '법인∙단체를 사실상 지배하는 사람의 정보'
        shareHolderInfo = staticShareHolderInfo
        break
      case Number(ShareHolderStatus.UNKNOWN):
        ownerInfo = [
          ['25% 이상의 지분을 소유한 사람이 있는 경우', '아니오'],
          ['위를 확인할 수 없습니다.', '예']
        ]
        detailOwnerInfo = [
          ['1. 최대 지분을 소유한 사람이 있는 경우', '아니오'],
          ['2. 대표자 또는 임원∙업무집행사원의 과반수를 선임한 주주(자연인)이 있는 경우', '아니오'],
          ['3. 위 두 경우 외에 법인∙단체를 사실상 지배하는 사람이 있는 경우', '아니오'],
          ['4. 모두 확인할 수 없습니다', '예']
        ]
        shareHolderTitle = '법인 또는 단체의 대표자 중 1인'
        shareHolderInfo = staticShareHolderInfo
        break
    }

    ownerBaseForm = [
      {
        title: `사업체 실제소유자 정보 (${ownerInfoTitleSuffix})`,
        type: 'table',
        data: ownerInfo,
        subText,
        columnClass: 'column-question'
      }
    ]
    if (detailOwnerInfo) {
      ownerBaseForm.push({
        type: 'table',
        data: detailOwnerInfo,
        columnClass: 'column-question-sub'
      })
    }
    if (shareHolderInfo) {
      ownerBaseForm.push({
        title: shareHolderTitle,
        type: 'table',
        data: shareHolderInfo
      })
    }
    return [
      {
        title: '거래 목적 및 자금 원천',
        type: 'table',
        data: purposeInfo,
        columnClass: 'column-title-expand'
      },
      ...ownerBaseForm
    ]
  }

  created () {
    this.corpAll = this.getCorpAllState
    this.forms = [
      this.getFirstPageData(),
      this.getSecondPageData()
    ]
  }
}
