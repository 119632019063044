import { SignUpDocumentPdfHandler } from '@/lib/pdf/SignUpDocumentPdfHandler'
import store from '@/store'
import { AffiliatedDocumentType } from '@/enums/AffiliatedDocumentType'
import { BusinessCategory } from '@/presentation/affiliate/CompanyInfoField'

export type DownloadOptions = {
  subTitle?: string;
  option: Array<{
    label: string;
    type: AffiliatedDocumentType;
  }>;
}

export type DocumentTypeOption = {
  type: AffiliatedDocumentType;
  title: string;
  component: string;
  selector: string;
  page: number;
}

export interface DocumentDownload {
  getDocumentTypeOptions (): Array<DocumentTypeOption>;
  getForms (category: BusinessCategory): Array<DownloadOptions>;

  formClass: string;
  disabledSubmit: boolean;
  view (type: AffiliatedDocumentType): Promise<void>;
  download (type: AffiliatedDocumentType): Promise<void>;

  subButtonLabel: string;
  submitLabel: string;
}

export class DocumentDownload implements DocumentDownload {
  constructor () {
    this.staticDocuments = new Map<AffiliatedDocumentType, string>()
    this.staticDocuments.set(AffiliatedDocumentType.INTRODUCE_ENTREPRENEUR, 'https://drive.google.com/file/d/13ym3WxMYDmii-GasoZl5Y42fjrZkHer6/view?usp=sharing')
    this.staticDocuments.set(AffiliatedDocumentType.INTRODUCE_CORPERATION, 'https://drive.google.com/file/d/1VN0PKbokE-L6EuhC1tdm83ZNQmD42yKb/view?usp=sharing')
    this.staticDocuments.set(AffiliatedDocumentType.PRIVACY_AGREEMENT, 'https://drive.google.com/file/d/176mnFaJpaxzj_F39DHVUb74BFooZSbXW/view?usp=sharing')
    this.staticDocuments.set(AffiliatedDocumentType.USAGE_TERMS, 'https://drive.google.com/file/d/1taV6QOfKkNesOkeRz2-hbeYZW-EBEslQ/view?usp=sharing')
  }
  formClass = 'divided-form'

  staticDocuments: Map<AffiliatedDocumentType, string>

  documentTypeOptions: Array<DocumentTypeOption> = [
    {
      type: AffiliatedDocumentType.USAGE_AGREEMENT,
      title: '센트비즈 서비스 이용약정서',
      component: 'UsageAgreement',
      selector: 'usage-agreement',
      page: 2
    },
    {
      type: AffiliatedDocumentType.TRADE_CONFIRMATION,
      title: '고객거래확인서',
      component: 'TradeConfirmation',
      selector: 'trade-confirmation',
      page: 2
    }
  ]

  getForms (category: BusinessCategory): Array<DownloadOptions> {
    const prefix = '서류 안내'
    const isEntrepreneur = Number(category) === Number(BusinessCategory.ENTREPRENEUR)
    const introLabel = isEntrepreneur ? `${prefix}_개인사업자` : `${prefix}_법인사업자`
    const introDocumentType = isEntrepreneur ? AffiliatedDocumentType.INTRODUCE_ENTREPRENEUR : AffiliatedDocumentType.INTRODUCE_CORPERATION
    return [
      {
        option: [
          { label: introLabel, type: introDocumentType },
          { label: '이용약관', type: AffiliatedDocumentType.USAGE_TERMS }
        ]
      },
      {
        subTitle: '다음의 문서에 서명 후 업로드 해주세요.',
        option: [
          { label: '이용약정서', type: AffiliatedDocumentType.USAGE_AGREEMENT },
          { label: '고객거래 확인서', type: AffiliatedDocumentType.TRADE_CONFIRMATION },
          { label: '개인정보등 활용 필수적 동의서', type: AffiliatedDocumentType.PRIVACY_AGREEMENT }
        ]
      }
    ]
  }

  getDocumentTypeOptions (): Array<DocumentTypeOption> {
    return this.documentTypeOptions
  }

  private getDocumentTypeOption (type: AffiliatedDocumentType): DocumentTypeOption | undefined {
    return this.documentTypeOptions.find(doc => {
      return Number(doc.type) === Number(type)
    })
  }

  private async setHtmlPdf (pdfHandler: SignUpDocumentPdfHandler, documentTypeOption: DocumentTypeOption) {
    for (let currentPage = 1; currentPage <= documentTypeOption.page; currentPage++) {
      const loadingMessage = `${documentTypeOption.title} 파일을 생성 중입니다. ${currentPage} / ${documentTypeOption.page}`
      store.commit('enableLoading', loadingMessage)
      const isFirstPage = currentPage === 1
      await pdfHandler.addHtmlImage(`#canvas-dialog-${documentTypeOption.selector}-${currentPage}`, isFirstPage)
    }
    store.commit('disableLoading')
  }

  openStaticDocuments (type: AffiliatedDocumentType) {
    const url = this.staticDocuments.get(type)
    if (!url) return
    window.open(url, '_blank')
  }

  async view (type: AffiliatedDocumentType) {
    if (this.staticDocuments.has(type)) return this.openStaticDocuments(type)
    const documentTypeOption = this.getDocumentTypeOption(type)
    if (!documentTypeOption) return
    const pdfHandler: SignUpDocumentPdfHandler = new SignUpDocumentPdfHandler()
    await this.setHtmlPdf(pdfHandler, documentTypeOption)
    pdfHandler.viewPdf()
  }

  async download (type: AffiliatedDocumentType) {
    if (this.staticDocuments.has(type)) return this.openStaticDocuments(type)
    const documentTypeOption = this.getDocumentTypeOption(type)
    if (!documentTypeOption) return
    const pdfHandler: SignUpDocumentPdfHandler = new SignUpDocumentPdfHandler()
    await this.setHtmlPdf(pdfHandler, documentTypeOption)
    pdfHandler.downloadPdf(documentTypeOption.title)
  }

  subButtonLabel = '문서작성으로 돌아가기'

  disabledSubmit = false
  submitLabel = '다음'
}
