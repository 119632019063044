// @ts-ignore
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export interface SignUpDocumentPdfHandler {
  addHtmlImage (selector: string, isFirstPage: boolean, pageWidth: number): void;
  viewPdf (): void;
  downloadPdf (fileName: string): void;
}

export class SignUpDocumentPdfHandler implements SignUpDocumentPdfHandler {
  private pdf: jsPDF
  constructor () {
    // eslint-disable-next-line new-cap
    this.pdf = new jsPDF('p', 'mm', 'a4')
  }

  addHtmlImage (selector: string, isFirstPage: boolean, pageWidth = 209): Promise<void> {
    const element: HTMLElement | null = document.querySelector(selector)
    if (!element) return new Promise((resolve, reject) => { return reject })
    element.setAttribute('style', 'display: block; position: absolute; left: -999px;')
    // ele.setAttribute('style', 'display: block;')
    const offsetWidth = element.offsetWidth
    const offsetHeight = element.offsetHeight
    const imgHeight = Math.floor(pageWidth * offsetHeight / offsetWidth)
    return html2canvas(element, { scale: 2 }).then((canvas) => {
      const base64Image = canvas.toDataURL('image/png', 1)
      if (!isFirstPage) this.pdf.addPage()
      this.pdf.addImage(base64Image, 'png', 0, 0, pageWidth, imgHeight, undefined, 'SLOW')
      element.setAttribute('style', 'display: none;')
    })
  }

  viewPdf (): void {
    this.pdf.output('dataurlnewwindow')
  }

  downloadPdf (fileName: string): void {
    this.pdf.save(`${fileName}.pdf`)
  }
}
